<template>
  <v-container>
    <v-toolbar elevation="1">
      <v-toolbar-title class="text-h5 font-weight-regular title"> Test - Liveness </v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-row justify="center">
      <v-col md="8" sm="12" class="pt-10 text-center">
        <v-file-input solo label="Upload image or video" v-model="imageOrVideo" />
        <v-btn color="success" :disabled="!imageOrVideo" @click="checkLiveness" :loading="loading">
          <v-icon>mdi-cloud-upload</v-icon>&nbsp; Test liveness
        </v-btn>
        <v-card v-if="livenessData" class="my-5">
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr v-for="item in items" :key="item.name">
                  <td class="text-right">{{ item.name }}</td>
                  <td class="text-left">
                    {{ item.value(livenessData) }}
                  </td>
                </tr>
                <tr v-if="livenessData.faces">
                  <td class="text-right">Faces</td>
                  <td class="text-left">
                    <div v-for="(face, i) in livenessData.faces" :key="i">
                      <b>Frame {{ i + 1 }}</b>
                      &mdash;
                      <span class="ml-1">{{ face.label }}</span>
                      &mdash;
                      {{
                        Math.round(
                          face.label === "SPOOF"
                            ? 100 * face.spoofConfidence
                            : 100 * face.liveConfidence
                        )
                      }}% sure.
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col md="12" class="pt-4 text-center">
        <v-card v-if="errorData" dark class="my-5 pa-3">
          <div class="overflow-x-auto">
            <pre class="red--text text-left">{{ JSON.stringify(errorData, null, 4) }}</pre>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { LIVENESS_CHECK } from "@/constants/api";
import moment from "moment";

export default {
  name: "TestLiveness",
  metaInfo: { title: "Test - Liveness" },
  components: {},
  data: () => ({
    loading: false,
    livenessData: null,
    errorData: null,
    imageOrVideo: null,
    items: [
      { name: "Request Id", value: (item) => item.requestId },
      { name: "Label", value: (item) => item.label },
      { name: "Model Name", value: (item) => item.modelName },
      { name: "Model Version", value: (item) => item.modelVersion },
      { name: "Confidence", value: (item) => Math.round(100 * item.confidence) + "%" },
      { name: "Fake Label Ratio", value: (item) => item.fakeLabelRatio },
      { name: "Frames Processed", value: (item) => item.framesProcessed },
      { name: "Processing Time", value: (item) => item.processingTime.toFixed(3) + " seconds" },
      {
        name: "Requested At",
        value: (item) => moment(item.createdAt).format("DD MMM, YYYY \n hh:mm:ss A"),
      },
    ],
  }),
  mounted() {
    this.livenessData = this.$store?.state?.test?.testLivenessData?.livenessData;
  },
  methods: {
    showError(err) {
      console.log(err);
      this.$iziToast.error({ title: err || "Something went wrong!" });
    },
    async checkLiveness() {
      this.loading = true;
      this.livenessData = null;
      this.errorData = null;
      try {
        const data = new FormData();
        data.append("face", this.imageOrVideo);
        const response = await this.$axios.post(LIVENESS_CHECK, data);
        this.livenessData = response.data?.data;
        this.$store.commit("test/setTestLivenessData", {
          livenessData: this.livenessData || null,
        });
      } catch (err) {
        this.errorData = err?.response?.data || err?.message;
      } finally {
        this.loading = false;
      }
    },
    // urlToFile(url, filename, mimeType) {
    //   return fetch(url)
    //     .then(function (res) {
    //       return res.arrayBuffer();
    //     })
    //     .then(function (buf) {
    //       return new File([buf], filename, { type: mimeType });
    //     });
    // },
  },
};
</script>

<style scoped></style>
